.Header {
  .menu,
  .cart {
    cursor: pointer;
  }

  .cart {
    .red-dot {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: var(--mantine-color-red-5);
    }
  }
}

.mantine-Drawer-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mantine-Drawer-header,
.mantine-Drawer-body {
  background-color: var(--mantine-color-black);
  color: var(--mantine-color-white);
  padding: 24px;
}

.mantine-Drawer-body {
  flex: 1;
  padding-top: 48px !important;
}
