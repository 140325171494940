.Order {
  .mantine-Carousel-control {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .mantine-Carousel-indicator {
    background-color: var(--mantine-color-black);
  }
}
