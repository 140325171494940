.mantine-Notification-root {
  &::before {
    display: none !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.login-modal,
.address-modal {
  .mantine-Modal-content {
    padding: 0 24px 24px;
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.mantine-Timeline-item {
  padding-left: 15px;
  .mantine-Timeline-itemBullet {
    width: 7px;
    height: 7px;
    top: 6px;
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }
  &::before {
    top: 7px;
    bottom: calc(17px * -1);
    border-color: #d9d9d9;
    left: -1px;
  }
}
.mantine-Timeline-item:not(:first-of-type) {
  margin-top: 10px;
}
