.ResetPassword {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
  padding: 25px;
  bottom: 100px;
  width: 100%;
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .link {
    position: relative;
    bottom: 30px;
  }
}
