.Item {
  .mantine-Carousel-control {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .mantine-Carousel-indicator {
    background-color: var(--mantine-color-black);
  }

  .mantine-Carousel-viewport,
  .mantine-Carousel-container {
    height: 100%;
  }

  .item-image {
    object-fit: cover;
  }

  .item-size {
    background-color: var(--mantine-color-white);
    color: var(--mantine-color-black);
  }
  .selected {
    background-color: var(--mantine-color-black);
    color: var(--mantine-color-white);
  }
}
